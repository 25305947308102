@media (max-width: 575px)

    .sections

        .container

            padding-left: 0
            padding-right: 0

@media (min-width: 576px) and (max-width: 767px)
    .container
        max-width: 570px
