body
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"

.toolbar-placeholder
    height: 46px

.cms-toolbar-expanded body, .cms-toolbar-expanded .navbar-fixed-top
    top: 30px

header

    .navbar

        &.tm-navbar.scroll
            /* offset-x | offset-y | blur-radius | spread-radius | color */
            box-shadow: 0 2px 10px 10px rgba(0, 0, 0, 0.2)

        .navbar-collapse
            flex-flow: row-reverse

        button
            border: 1px solid $btn-secondary-border

            i
                color: $text-primary-color

    .tm-hero-text-container

        // enforce same style for all titles
        h1,h2,h3,h4,h5,h6,h7
            @extend .tm-hero-title
.about
    background-color: $bg-primary-color

    // enforce same style for all titles
    h1,h2,h3,h4,h5,h6,h7
        @extend .tm-section-title
        @extend .tm-text-primary

    p
        @extend .tm-intro-text

.sections

    background-color: $bg-primary-color
    padding-bottom: 135px

    img
        object-fit: cover

    .section-row

        margin-left: 15px
        margin-right: 15px
        background-color: white
        clear: both
        box-shadow: 0 0 10px $box-shadow-color

        .tm-intro-text p

            font-size: 1.2rem
            line-height: 1.4

    .tm-intro-text-container
        padding-right: 15px

        @media ( min-width: 992px )

            .tm-section-title
                margin-top: 15px

    .section-row .column
        padding: 0

    .section-row.alternate
        flex-direction: row-reverse

    .tm-btn-primary
        text-decoration: none
        margin-top: 15px

    // .section-row img
        // object-fit: cover
        // width: 100%
        // height: 350px

div.btn-section-info

    margin-top: 45px
    margin-bottom: 45px

footer

    a.tm-contact-item-link
        text-decoration: none

    #contact
        padding-bottom: 25px
